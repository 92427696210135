import {
  contentStore as novartisContentStore,
  NovartisContentStore,
} from 'projects/novartis_live/stores/contentStore'
import {
  contentStore as nuclearGamesContentStore,
  NuclearGamesContentStore,
} from 'projects/nuclear_games/stores/contentStore'
import { AppName } from 'utils/enums'
import { contentStoreSingleton } from './getContentStore'
import { globalStore, ContentStore } from './globalStore'

type Store = ContentStore | NovartisContentStore | NuclearGamesContentStore
export const getStore = (): Store => {
  switch (process.env.REACT_APP_PROJECT_NAME) {
    case AppName.Novartis:
      return novartisContentStore
    case AppName.NuclearGames:
      return nuclearGamesContentStore
    default:
      return globalStore
  }
}

export const contentStore = getStore()
contentStoreSingleton.contentStore = contentStore
window.contentStore = contentStore
