import {
  IResponsiveUrlPrismicData,
  IWithUrl,
} from 'stores/contentStore/cmsAdapters/prismic/prismicSubcontentDataProcessor'
import bind from 'bind-decorator'
import { appConfig } from 'config'
import { DateTime } from 'luxon'
import { action, computed, observable } from 'mobx'
import FullscreenSubcontentAudio from 'projects/novartis_live/subcontent_modules/audio/subcontent'
import {
  identifier,
  list,
  map,
  object,
  primitive,
  serializable,
} from 'serializr'
import { currentStory } from 'stores/currentStoryStore'
import { decodingRouterHelper } from 'stores/routerStore'
import { sliderStore } from 'stores/sliderStore'
import { getContentStore } from './getContentStore'
import {
  createSectionItem,
  isoDate,
  leaveUnchagedListFactory,
} from './mobxHelpers'
import { SectionItem } from './sectionItems/SectionItem'
import {
  IPrismicBackIssues,
  ISubcontentPrismicData,
} from 'stores/prismicHelpers'

export type ContentTypes = 'chapter' | 'subchapter'

export class Dimensions {
  @serializable
  width?: number

  @serializable
  height?: number
}

export class ResponsiveUrl {
  @serializable
  url: string = ''

  @serializable(object(Dimensions))
  dimensions?: Dimensions

  @serializable
  @observable
  alt?: string = ''
}

export class Thumbnail {
  @serializable
  @observable
  url: string = ''

  @serializable(map(object(ResponsiveUrl)))
  @observable
  responsiveUrls: { [id: string]: ResponsiveUrl } = {}

  @serializable
  @observable
  alt?: string = ''
}

export class Poster {
  @serializable
  @observable
  url: string = ''

  @serializable(map(object(ResponsiveUrl)))
  @observable
  responsiveUrls: { [id: string]: ResponsiveUrl } = {}
}

export class ChapterLocalization {
  @serializable
  @observable
  language: string = ''

  @serializable
  @observable
  uid?: string = ''
}

export interface IChapter {
  id: string
  type: ContentTypes
  uid: string
  localizations: ChapterLocalization[]
  idx: number
  title: string
  color: string
  category: string
  categoryAlternativeText?: string
  author: string
  leadText: string
  abstract: string
  keyText: string
  subTitle: string
  ranking: number
  isFeaturedOnTopBanner: boolean
  isFeaturedWithBackgroundImage: boolean
  isFeaturedWithGrayBackground: boolean
  publicationDate: DateTime
  hubTeaserText: string
  relatedArticles: string[]
  tags: string[]
  subchaptersIds: string[]
  thumbnail: Thumbnail | null
  backgroundImage: string
  worldImage: string
  worldMarker: string
  lottieChapterTitleAnimation: string
  introText: string
  nextChapterButtonText: string
  background: string
  introVideoMobile: string
  introVideoDesktop: string
  nextChapterBackground: string
  outroText: string
  noCover: boolean
  iconBeforeHeadline?: string
  durationOfPodcast?: number
  isScrollytellingChapter: boolean
  password?: string
}

export class Chapter implements IChapter {
  @serializable(identifier())
  @observable
  id: string = ''

  @serializable
  @observable
  type: ContentTypes = 'chapter'

  @serializable
  @observable
  uid: string = ''

  @serializable(list(object(ChapterLocalization)))
  @observable
  localizations: ChapterLocalization[] = []

  @serializable
  @observable
  idx: number = 0

  @serializable
  @observable
  title: string = ''

  @serializable
  @observable
  color: string = ''

  @serializable
  @observable
  category: string = ''

  @serializable
  @observable
  categoryAlternativeText?: string = ''

  @serializable
  @observable
  author: string = ''

  @serializable
  @observable
  leadText: string = ''

  @serializable
  @observable
  outroText: string = ''

  @serializable
  @observable
  abstract: string = ''

  @serializable
  @observable
  keyText: string = ''

  @serializable
  @observable
  subTitle: string = ''

  @serializable
  @observable
  ranking: number = -1

  @serializable
  @observable
  isFeaturedOnTopBanner: boolean = false

  @serializable
  @observable
  isFeaturedWithBackgroundImage: boolean = false

  @serializable
  @observable
  isFeaturedWithGrayBackground: boolean = false

  @serializable(isoDate())
  @observable
  publicationDate: DateTime = DateTime.local()

  @serializable
  @observable
  hubTeaserText: string = ''

  @serializable(list(primitive()))
  @observable
  relatedArticles: string[] = []

  @serializable(list(primitive()))
  @observable
  tags: string[] = []

  @serializable(list(primitive()))
  @observable
  subchaptersIds: string[] = []

  @serializable(object(Thumbnail))
  @observable
  thumbnail: Thumbnail | null = null

  @serializable
  @observable
  backgroundImage: string = ''

  @serializable
  @observable
  worldImage: string = ''

  @serializable
  @observable
  worldMarker: string = ''

  @serializable
  @observable
  lottieChapterTitleAnimation: string = ''

  @serializable
  @observable
  introText: string = ''

  @serializable
  @observable
  nextChapterButtonText: string = ''

  @serializable
  @observable
  background: string = ''

  @serializable
  @observable
  introVideoMobile: string = ''

  @serializable
  @observable
  introVideoDesktop: string = ''

  @serializable
  @observable
  nextChapterBackground: string = ''

  @serializable
  @observable
  noCover: boolean = false

  @serializable
  @observable
  isScrollytellingChapter: boolean = false

  @serializable
  @observable
  password?: string = ''

  @serializable
  @observable
  iconBeforeHeadline?: string

  @serializable
  @observable
  durationOfPodcast?: number

  @observable
  coverLoaded: boolean = false

  @computed
  get subchaptersFetched() {
    const contentStore = getContentStore()
    return this.subchaptersIds.every(
      (id) => !!contentStore?.subchapters.get(id)
    )
  }

  @bind
  @action
  loadCover() {
    this.coverLoaded = true
  }

  getLocalizationPath(language: string, errorPath?: string) {
    const loc = this.localizations.filter((loc) => loc.language === language)[0]
    if (loc) {
      return `/${appConfig.contentUnitUrl}/${loc.uid ?? ''}`
    }
    if (errorPath) {
      return errorPath
    }
    return appConfig.landingPageRoute
  }

  @computed
  get timeToRender(): boolean {
    const isActive = sliderStore.currentChapter === this
    const contentStore = getContentStore()
    if (!contentStore) {
      return false
    }
    const nextChapterLoaded = !!contentStore.getChapterShiftedBy(1, this)
      ?.sectionsLoaded
    const previousChapterLoaded = !!contentStore.getChapterShiftedBy(-1, this)
      ?.sectionsLoaded
    const hasPassword = sliderStore.currentChapter?.password

    const render = !!(
      this.sectionsLoaded ||
      (isActive && !this.sectionsLoaded) ||
      previousChapterLoaded ||
      nextChapterLoaded ||
      hasPassword
    )
    return render
  }

  @computed
  get subcontents() {
    const contentStore = getContentStore()
    if (!contentStore) {
      return []
    }
    const sections: Subchapter[] = this.subchaptersIds
      .map((id) => contentStore.subchapters.get(id))
      .filter((section) => section) as Subchapter[]
    const subcontents = sections.map((section) =>
      currentStory.useFallbackBackgrounds
        ? contentStore.fullscreenSubcontent.get(
            section.fallbackBackgroundId ?? ''
          )
        : contentStore.fullscreenSubcontent.get(section.backgroundId)
    )
    return subcontents
  }

  @computed
  get sectionsLoaded() {
    if (!this.subcontents.length) {
      return false
    }
    return this.subcontents.every((subcontent) => subcontent?.resourcesLoaded)
  }
}

export type PartialChapter = Partial<Chapter> & {
  id: string
  uid: string
  type: ContentTypes
  idx: number
  title: string
  category: string
  categoryAlternativeText?: string
  author: string
  thumbnail: Thumbnail | null
  lottieChapterTitleAnimation: string
  ranking: number
  publicationDate: DateTime
  hubTeaserText: string
  tags: string[]
  subchaptersIds: string[]
}

export interface ISubchapter {
  id: string
  type: ContentTypes
  uid: string
  backgroundId: string
  fallbackBackgroundId?: string
  thumbnail: Thumbnail | null
  title: string
  styledPrefix: string
  sidebarText: string
  sectionItems: SectionItem[]
  subheaders?: string[]
  textPositionLeft?: boolean
}

export class Subchapter implements ISubchapter {
  @serializable(identifier())
  @observable
  id: string = ''

  @serializable
  @observable
  type: ContentTypes = 'subchapter'

  @serializable
  @observable
  uid: string = ''

  @serializable
  @observable
  backgroundId: string = ''

  @serializable
  @observable
  fallbackBackgroundId?: string = ''

  @serializable(object(Thumbnail))
  @observable
  thumbnail: Thumbnail | null = null

  @serializable
  @observable
  title: string = ''

  @serializable
  @observable
  styledPrefix: string = ''

  @serializable
  @observable
  sidebarText: string = ''

  @serializable(leaveUnchagedListFactory(createSectionItem))
  @observable
  sectionItems: SectionItem[] = []

  @serializable(list(primitive()))
  @observable
  subheaders?: string[] = []

  @serializable
  @observable
  textPositionLeft?: boolean = false

  @computed
  get subcontentId() {
    const parsed = decodingRouterHelper.qsParsed
    const mainBgId = this.backgroundId
    const fallbackBgId = this.fallbackBackgroundId
    const bgId =
      currentStory.useFallbackBackgrounds && fallbackBgId
        ? fallbackBgId
        : mainBgId
    const result =
      parsed.subcontentId && this.id === currentStory.activeSectionId
        ? (parsed.subcontentId as string)
        : bgId
    return result
  }
}

export class LegalSectionEntry {
  @serializable
  year: string = ''

  @serializable
  title: string = ''

  @serializable
  entry: string = ''
}

export class LegalSection {
  @serializable
  title: string = ''
  @serializable(list(object(LegalSectionEntry)))
  entries: LegalSectionEntry[] = []
}

export class SupporterLogo {
  @serializable
  image: string = ''

  @serializable
  link: string = ''
}

export class SupportersLogos {
  @serializable
  year: string = ''

  @serializable(list(object(SupporterLogo)))
  logos: SupporterLogo[] = []
}

export class LegalContent {
  @serializable
  id: string = ''

  @serializable
  title: string = ''

  @serializable(list(object(LegalSection)))
  sections: LegalSection[] = []

  @serializable(list(object(SupportersLogos)))
  supportersLogos?: SupportersLogos[] = []
}

export interface IIEBanner {
  header: string
  logo: string
  textGerman: string
  textEnglish: string
}

export class IEBanner implements IIEBanner {
  @serializable
  @observable
  header: string = ''

  @serializable
  @observable
  logo: string = ''

  @serializable
  @observable
  textGerman: string = ''

  @serializable
  @observable
  textEnglish: string = ''
}

export interface ICookiesBanner {
  message: string
  acceptButtonText: string
  moreInfoText: string
}

export class CookiesBanner implements ICookiesBanner {
  @serializable
  @observable
  message: string = ''

  @serializable
  @observable
  acceptButtonText: string = ''

  @serializable
  @observable
  moreInfoText: string = ''
}

export interface IVideoBanner {
  text: string
  buttonText: string
  banner: string
  background: string
}

export class VideoBanner implements IVideoBanner {
  @serializable
  @observable
  text: string = ''

  @serializable
  @observable
  buttonText: string = ''

  @serializable
  @observable
  banner: string = ''

  @serializable
  @observable
  background: string = ''
}

export interface ILegalItemsInMenu {
  legalDisplay: string
  legalLink: string
}

export class LegalItemsInMenu implements ILegalItemsInMenu {
  @serializable(primitive())
  @observable
  legalDisplay: string = ''

  @serializable(primitive())
  @observable
  legalLink: string = ''
}
export interface IMenuItems {
  buttonTextStartChapter: string
  buttonTextChapterOverview: string
  buttonTextLinkToSequence: string
  placeholderImage: string
  closeMenuText: string
  backToLandingText: string
  knowledgeBaseLinkTitle: string
  knowledgeBaseLinkDesc: string
  legalEntries: LegalItemsInMenu[]
  documentaryLinkTitle: string
  documentaryLinkDesc: string
}
export class MenuItems implements IMenuItems {
  @serializable
  @observable
  buttonTextStartChapter: string = ''

  @serializable
  @observable
  buttonTextChapterOverview: string = ''

  @serializable
  @observable
  buttonTextLinkToSequence: string = ''

  @serializable
  @observable
  placeholderImage: string = ''

  @serializable
  @observable
  closeMenuText: string = ''

  @serializable
  @observable
  backToLandingText: string = ''

  @serializable
  @observable
  knowledgeBaseLinkTitle: string = ''

  @serializable
  @observable
  knowledgeBaseLinkDesc: string = ''

  @serializable
  @observable
  documentaryLinkTitle: string = ''

  @serializable
  @observable
  documentaryLinkDesc: string = ''

  @serializable(list(object(LegalItemsInMenu)))
  @observable
  legalEntries: LegalItemsInMenu[] = []
}

export interface IMetaTab {
  title: string
}

export class MetaTab {
  @serializable(primitive())
  name: string = ''

  @serializable(primitive())
  displayedTitle: string = ''
}

export class UnlockContent {
  @serializable
  @observable
  header: string = ''

  @serializable
  @observable
  title: string = ''

  @serializable
  @observable
  warningText: string = ''

  @serializable
  @observable
  backButtonText: string = ''

  @serializable
  @observable
  unlockButtonText: string = ''

  @serializable
  @observable
  thumbnail: string = ''

  @serializable(list(primitive()))
  @observable
  forbiddenURLs: string[] = []
}

export class Dossier {
  @serializable
  @observable
  title: string = ''

  @serializable
  @observable
  collectionTypeUnit: string = ''

  @serializable
  @observable
  description: string = ''

  @serializable(object(Thumbnail))
  @observable
  thumbnail?: Thumbnail

  @serializable(list(primitive()))
  @observable
  articleIds: string[] = []

  @serializable(list(object(Chapter)))
  @observable
  articles?: Chapter[] = []

  @serializable
  @observable
  ranking: number = -1

  @serializable
  @observable
  orderByName: boolean = false
}

export interface IUIElements {
  SEOTitle: string
  SEOSubtitle: string
  SEODescription: string
  SEOKeywords: string[]
  SEOImage: string
  chapterDateFormat: string
  scrollToIntroText: string
  idleTimePopupText: string
  blockedAudioAutoplayTextInfo: string
  documentaryURL: string
  ieBanner?: IEBanner
  cookiesBanner?: CookiesBanner
  videoBanner?: VideoBanner
  signupBanner?: ISignupBanner
  menuItems?: MenuItems
  metaTabs: MetaTab[]
  staticUITexts?: StaticUITexts
  podcast?: Podcast
  IEPreviousChapterButtonText?: string
  IENextChapterButtonText?: string
  loadingSpinner?: string
  soundIconOn: string
  soundIconOff: string
  soundOnPhrase: string
  soundOffPhrase: string
  loadingScreen?: LoadingScreen
  unlockContent?: UnlockContent
  podcastSeries?: Series[]
  dossiers?: Dossier[]
  passwordSubmitButtonText?: string
  passwordWrong?: string
}

export class SignupBanner {
  @serializable
  @observable
  title = ''

  @serializable
  @observable
  description = ''

  @serializable
  @observable
  inputPlaceholder = ''

  @serializable
  @observable
  buttonText = ''

  @serializable
  @observable
  checkboxLabel = ''

  @serializable
  @observable
  emailActionUrl = ''

  @serializable
  @observable
  idMailchimp = ''

  @serializable
  @observable
  userMailchimp = ''

  @serializable
  @observable
  emailMailchimp = ''

  @serializable
  @observable
  background = ''
}

export interface ISignupBanner {
  title: string
  description: string
  inputPlaceholder: string
  buttonText: string
  checkboxLabel: string
  emailActionUrl: string
  idMailchimp: string
  userMailchimp: string
  emailMailchimp: string
  background?: string
}

export interface ICategory {
  name: string
  filter: string
  longName: string
  description: string
  img?: Poster
  goToText: string
}

export class Category implements ICategory {
  @serializable
  @observable
  name: string = ''

  @serializable
  @observable
  filter: string = ''

  @serializable
  @observable
  longName: string = ''

  @serializable
  @observable
  description: string = ''

  @serializable(object(Poster))
  @observable
  img: Poster | undefined = undefined

  @serializable
  @observable
  goToText: string = ''

  @computed
  get displayedURL() {
    return this.name
      .toLowerCase()
      .trim()
      .replace(/[^a-zA-Z0-9-_]/g, '')
  }
}

export interface IStaticUITexts {
  categories: Category[]
}

export class StaticUITexts implements IStaticUITexts {
  @serializable(list(object(Category)))
  @observable
  categories: Category[] = []

  @serializable
  @observable
  allStories: string = ''

  @serializable
  @observable
  recent: string = ''

  @serializable
  @observable
  taggedBy: string = ''

  @serializable
  @observable
  readNow: string = ''

  @serializable
  @observable
  moreArticles: string = ''

  @serializable
  @observable
  allArticles: string = ''

  @serializable
  @observable
  discoverOurCategories: string = ''

  @serializable
  @observable
  readAbstract: string = ''

  @serializable
  @observable
  contents: string = ''

  @serializable
  @observable
  relatedArticlesInfo: string = ''

  @serializable
  @observable
  rotateDeviceText: string = ''

  @serializable
  @observable
  chapterText: string = ''

  @serializable
  @observable
  IEPreviousChapterButtonText: string = ''

  @serializable
  @observable
  IENextChapterButtonText: string = ''
}

export interface IWaitingText {
  text: string
}

export class WaitingText implements IWaitingText {
  @serializable
  @observable
  text: string = ''
}

export interface ILoadingScreen {
  waitingTexts: WaitingText[]
}

export class LoadingScreen implements ILoadingScreen {
  @serializable(list(object(WaitingText)))
  @observable
  waitingTexts: WaitingText[] = []

  @serializable
  @observable
  loadingSpinner: string = ''

  @serializable
  @observable
  backgroundImage: string = ''

  @serializable
  @observable
  infotextFallbackVersion: string = ''

  @serializable
  @observable
  fallbackNoButtonText: string = ''

  @serializable
  @observable
  fallbackYesButtonText: string = ''

  @serializable
  @observable
  infoTextAfterRotatingDevice: string = ''
}
export interface ISeries {
  title: string
  hubTitle: string
  collectionTypeUnit: string
  description: string
  thumbnail?: Thumbnail
  ranking: number
  orderByName: boolean
}
export class Series implements ISeries {
  @serializable
  @observable
  title: string = ''

  @serializable
  @observable
  hubTitle: string = ''

  @serializable
  @observable
  collectionTypeUnit: string = ''

  @serializable
  @observable
  description: string = ''

  @serializable(object(Thumbnail))
  @observable
  thumbnail?: Thumbnail

  @serializable(list(primitive())) //this should be list(FullscreenSubcontentAudio) but then it breaks as of circular dependency
  @observable
  podcasts?: FullscreenSubcontentAudio[] = []

  @serializable(list(primitive()))
  @observable
  podcastIds: string[] = []

  @serializable
  @observable
  ranking: number = -1

  @serializable
  @observable
  orderByName: boolean = false
}
export interface IPodcast {
  buttonTextShowMore: string
  buttonTextShowLess: string
  relatedPodcastsTitle: string
  relatedPodcastsButtonTextShowMore: string
  mobileDescriptionTitle: string
  initialNumberOfRelatedEpisodes: number
}

export class Podcast implements IPodcast {
  @serializable
  @observable
  buttonTextShowMore: string = ''

  @serializable
  @observable
  buttonTextShowLess: string = ''

  @serializable
  @observable
  relatedPodcastsTitle: string = ''

  @serializable
  @observable
  relatedPodcastsButtonTextShowMore: string = ''

  @serializable
  @observable
  mobileDescriptionTitle: string = ''

  @serializable
  @observable
  initialNumberOfRelatedEpisodes: number = 0
}

export class UIElements implements IUIElements {
  IEPreviousChapterButtonText?: string | undefined
  IENextChapterButtonText?: string | undefined

  @serializable
  @observable
  documentaryURL: string = ''

  @serializable
  @observable
  scrollToIntroText: string = ''

  @serializable
  @observable
  idleTimePopupText: string = ''

  @serializable
  @observable
  blockedAudioAutoplayTextInfo: string = ''

  @serializable
  @observable
  soundIconOn: string = ''

  @serializable
  @observable
  soundIconOff: string = ''

  @serializable
  @observable
  soundOnPhrase: string = ''

  @serializable
  @observable
  soundOffPhrase: string = ''

  @serializable
  @observable
  SEOTitle: string = ''

  @serializable
  @observable
  SEOSubtitle: string = ''

  @serializable
  @observable
  SEODescription: string = ''

  @serializable(list(primitive()))
  @observable
  SEOKeywords: string[] = []

  @serializable
  @observable
  SEOImage: string = ''

  @serializable
  @observable
  chapterDateFormat: string = ''

  @serializable(object(IEBanner))
  @observable
  ieBanner?: IEBanner

  @serializable(object(CookiesBanner))
  @observable
  cookiesBanner?: CookiesBanner

  @serializable(object(VideoBanner))
  @observable
  videoBanner?: VideoBanner

  @serializable(object(MenuItems))
  @observable
  menuItems?: MenuItems

  @serializable(list(object(MetaTab)))
  @observable
  metaTabs: MetaTab[] = []

  @serializable(list(object(Series)))
  @observable
  podcastSeries?: Series[] = []

  @serializable(list(object(Dossier)))
  @observable
  dossiers?: Dossier[] = []

  @serializable(object(StaticUITexts))
  @observable
  staticUITexts?: StaticUITexts

  @serializable(object(LoadingScreen))
  @observable
  loadingScreen?: LoadingScreen

  @serializable(object(Podcast))
  @observable
  podcast?: Podcast

  @serializable
  @observable
  passwordSubmitButtonText?: string = ''

  @serializable
  @observable
  passwordWrong?: string = ''

  @serializable(object(StaticUITexts))
  @observable
  loadingSpinner?: string

  @serializable(object(SignupBanner))
  @observable
  signupBanner?: ISignupBanner

  @serializable(object(UnlockContent))
  @observable
  unlockContent?: UnlockContent
}

class IntroAnimation {
  @serializable
  @observable
  desktop: string = ''

  @serializable
  @observable
  tablet: string = ''

  @serializable
  @observable
  mobile: string = ''
}
export interface IPromotionBar {
  promoBarText: string
  promoButtonText: string
  promoButtonLink: string
  promoBarBackgroundColor: string
}

class PromotionBar {
  @serializable
  @observable
  promoBarText: string = ''

  @serializable
  @observable
  promoButtonText: string = ''

  @serializable
  @observable
  promoButtonLink: string = ''

  @serializable
  @observable
  promoBarBackgroundColor: string = ''
}

export interface IAwards {
  logo: string
  logoLink: string
}

class Awards {
  @serializable
  @observable
  logo: string = ''

  @serializable
  @observable
  logoLink: string = ''
}

export interface IIntroPage {
  lang: string
  title: string
  richTitle: string
  secondaryTitle: string
  backgroundImage: string
  startButtonText: string
  introductionMovieTitle: string
  skipVideoButtonText: string
  languageSelectionPhrase: string
  soundSwitchOn: string
  soundSwitchOff: string
  titlePoster: string
  introVideo: string
  introAnimation: IntroAnimation | null
  backgroundVideoLoopDesktop: string
  backgroundVideoLoopMobile: string
  promotionBar: PromotionBar | null
  awards: Awards[] | null
}

export class IntroPage implements IIntroPage {
  @serializable
  @observable
  lang: string = ''

  @serializable
  @observable
  title: string = ''

  @serializable
  @observable
  secondaryTitle: string = ''

  @serializable
  @observable
  richTitle: string = ''

  @serializable
  @observable
  backgroundImage: string = ''

  @serializable
  @observable
  startButtonText: string = ''

  @serializable
  @observable
  languageSelectionPhrase: string = ''

  @serializable
  @observable
  soundSwitchOn: string = ''

  @serializable
  @observable
  soundSwitchOff: string = ''

  @serializable
  @observable
  titlePoster: string = ''

  @serializable
  @observable
  introVideo: string = ''

  @serializable(object(IntroAnimation))
  @observable
  introAnimation: IntroAnimation | null = null

  @serializable
  @observable
  introductionMovieTitle: string = ''

  @serializable
  @observable
  skipVideoButtonText: string = ''

  @serializable
  @observable
  backgroundVideoLoopDesktop: string = ''

  @serializable
  @observable
  backgroundVideoLoopMobile: string = ''

  @serializable(object(PromotionBar))
  @observable
  promotionBar: PromotionBar | null = null

  @serializable(list(object(Awards)))
  @observable
  awards: Awards[] | null = null
}
export interface IKnowledgeBase {
  lang: string
  buttonTextToMain: string
  title: string
  subtitle: string
  description: string
  logoTopLeft: string
  logoDescriptionTopLeft: string
  backgroundImage: string
}

export class KnowledgeBase implements IKnowledgeBase {
  @serializable
  @observable
  lang: string = ''

  @serializable(primitive())
  @observable
  buttonTextToMain: string = ''

  @serializable(primitive())
  @observable
  title: string = ''

  @serializable(primitive())
  @observable
  subtitle: string = ''

  @serializable
  @observable
  description: string = ''

  @serializable
  @observable
  logoTopLeft: string = ''

  @serializable(primitive())
  @observable
  logoDescriptionTopLeft: string = ''

  @serializable
  @observable
  backgroundImage: string = ''
}

export interface IGetInTouch {
  type: 'subscribe' | 'pensioners' | 'about' | undefined
  text: string
  buttonText: string
  mailTo?: string
  mailToSubject?: string
  mailToBody?: string
  placeholderText?: string
}

export class GetInTouch implements IGetInTouch {
  @serializable
  @observable
  type: 'subscribe' | 'pensioners' | 'about' | undefined = undefined

  @serializable
  @observable
  text: string = ''

  @serializable
  @observable
  buttonText: string = ''

  @serializable
  @observable
  placeholderText?: string = ''

  @serializable
  @observable
  mailTo?: string = ''

  @serializable
  @observable
  mailToSubject?: string = ''

  @serializable
  @observable
  mailToBody?: string = ''
}

export interface IBackIssue {
  cover: string | undefined
  title: string
  subtitle: string
  description: string
  orderPerEmail: boolean
  orderOnExternal: boolean
  pdf?: string
  orderOnExternalLink?: string
  mailToAddress?: string
  mailToSubject?: string
  mailToBody?: string
}

export class BackIssue implements IBackIssue {
  @serializable
  @observable
  cover: string = ''

  @serializable
  @observable
  title: string = ''

  @serializable
  @observable
  subtitle: string = ''

  @serializable
  @observable
  description: string = ''

  @serializable
  @observable
  orderPerEmail: boolean = false

  @serializable
  @observable
  orderOnExternal: boolean = false

  @serializable
  @observable
  orderOnExternalLink?: string = ''

  @serializable
  @observable
  pdf?: string = ''

  @serializable
  @observable
  mailToAddress?: string = ''

  @serializable
  @observable
  mailToSubject?: string = ''

  @serializable
  @observable
  mailToBody?: string = ''
}
export interface IBackIssueSection {
  teaserTitleSection: string
  initialDisplayTeaser: number
  teaserTextTitle: string
  teaserTextDescription?: string
  backIssuesTitle: string
  backIssuesSubtitle: string
  initialDisplayAll: number
  issuesLoaded: number
  backToHubText: string
  downloadButtonText: string
  alternativeDownloadButtonText?: string
  orderOnExternalButtonText?: string
  loadMoreButtonText?: string
  teaserMoreButtonText?: string
  backIssueItems: BackIssue[]
}

export class BackIssuesSection implements IBackIssueSection {
  @serializable
  @observable
  teaserTitleSection: string = ''

  @serializable
  @observable
  initialDisplayTeaser: number = 3

  @serializable
  @observable
  teaserTextTitle: string = ''

  @serializable
  @observable
  teaserTextDescription?: string = ''

  @serializable
  @observable
  backIssuesTitle: string = ''

  @serializable
  @observable
  backIssuesSubtitle: string = ''

  @serializable
  @observable
  initialDisplayAll: number = 6

  @serializable
  @observable
  issuesLoaded: number = 0

  @serializable(primitive())
  @observable
  backToHubText: string = ''

  @serializable(primitive())
  @observable
  downloadButtonText: string = ''

  @serializable(primitive())
  @observable
  alternativeDownloadButtonText?: string = ''

  @serializable(primitive())
  @observable
  orderOnExternalButtonText?: string = ''

  @serializable(primitive())
  @observable
  loadMoreButtonText: string = ''

  @serializable(primitive())
  @observable
  teaserMoreButtonText: string = ''

  @serializable(list(object(BackIssue)))
  @observable
  backIssueItems: BackIssue[] = []
}

export interface IHub {
  dummyThumbnail: string
  articlesLoaded: number
  featuredArticlesTopBannerIds: string[]
  featuredArticle1: PartialChapter | null
  featuredArticle2: PartialChapter | null
  aboutLiveData: GetInTouch | null
  subscribeData: GetInTouch | null
  pensionersData: GetInTouch | null
  backIssues: BackIssuesSection[] | null
}

export class Hub implements IHub {
  @serializable
  @observable
  dummyThumbnail: string = ''

  @serializable
  @observable
  articlesLoaded: number = 0

  @serializable(list(primitive()))
  @observable
  featuredArticlesTopBannerIds: string[] = []

  @serializable(object(Chapter))
  @observable
  featuredArticle1: PartialChapter | null = null

  @serializable(object(Chapter))
  @observable
  featuredArticle2: PartialChapter | null = null

  @serializable(object(GetInTouch))
  @observable
  aboutLiveData: GetInTouch | null = null

  @serializable(object(GetInTouch))
  @observable
  subscribeData: GetInTouch | null = null

  @serializable(object(GetInTouch))
  @observable
  pensionersData: GetInTouch | null = null

  @serializable(list(object(BackIssuesSection)))
  @observable
  backIssues: BackIssuesSection[] | null = null
}

export interface IVideoBookEndPrismicData {
  /* eslint-disable */
  title_quote: string
  title_quote_first: string
  title_quote_second: string
  link_to_end_film_witnesses: IWithUrl
  caption: string
  poster_image: IWithUrl
  background_image: IWithUrl
  poster_button_text: string
  implementation_title: string
  implementation: string
  read_more: string
  videobooks_title: string
  newsletter_sign_up_title: string
  newsletter_sign_up_description: string
  newsletter_sign_up_input_placeholder: string
  newsletter_sign_up_button: string
  newsletter_sign_up_checkbox_label: string
  action_url_to_mailchimp: IWithUrl
  id_mailchimp: string
  user_mailchimp_no: string
  name_attribute_mailchimp_for_email_field: string
  body: Array<{
    primary: {
      thumbnail_videobook: IWithUrl
      videobook_title: string
      videobook_description: string
      link_to_videobook: IWithUrl
    }
  }>
  /* eslint-enable */
}

/* eslint-disable */
export interface ISubchapterPrismicData {
  id: string
  uid?: string
  data: {
    body: ISubcontentPrismicData[]
    bg_main: {
      id: string
    }
    link_to_subchapter_fallback_background: {
      id: string
    }
    thumbnail: IResponsiveUrlPrismicData
    subchapter_title: string
    prefix_vertical_subchapter_title: string
    sidebar_text: string
    text_position_left: boolean
  }
}
/* eslint-enable */

export interface IHubPrismicData {
  /* eslint-disable */
  data: {
    featured_article_1: {
      id: string
    }
    featured_article_2: {
      id: string
    }
    featured_top_banner: Array<{
      featured_article_top_banner: {
        id: string
      }
    }>
    dummy_image: IWithUrl
    default_amount_of_articles_shown: number
    subscribe_description: string[]
    button_text_subscribe: string
    subscribe_input_placeholder_text: string
    mailto_address_button_subscribe: string
    mailto_subject_subscribe: string
    mailto_body_subscribe: string
    pensioners_description: string[]
    button_text_pensioners: string
    mailto_address_button_pensioners: string
    mailto_subject_pensioners: string
    mailto_body_pensioners: string
    about_live: string
    button_text_about_live: string
    mailto_address_button_about_live: string
    mailto_subject_about_live: string
    body: IPrismicBackIssues[]
  }
  /* eslint-enable */
}

export interface IUICategoryPrismicData {
  /* eslint-disable */
  category_name: Array<{ text: string }>
  filter_name: string
  category_long_name: Array<{ text: string }>
  category_description: Array<{ text: string }>
  image_category_thumbnail: IResponsiveUrlPrismicData
  category_go_to_text: string
  /* eslint-enable */
}

export interface IInlineOwrksheetPrimsicData {
  primary: {
    /* eslint-disable */
    title_inline_work_sheet: string
    inline_worksheet_thumbnail: IWithUrl
    inline_work_sheet_link_to_pdf: IWithUrl
    description: string
    /* eslint-enable */
  }
}
