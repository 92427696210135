import { createMuiTheme } from '@material-ui/core'
import { ThemeProvider } from '@material-ui/styles'
import { appConfig, fullTheme, loadConfig } from 'config'
import 'core-js'
import { createBrowserHistory } from 'history'
import { configure } from 'mobx'
import { syncHistoryWithStore } from 'mobx-react-router'
import React from 'react'
import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import ReactDOM from 'react-dom'
import { Route, Router } from 'react-router-dom'
import smoothscroll from 'smoothscroll-polyfill'
import { getAdapter } from 'stores/adapterHelper'
import { ContentStore } from 'stores/contentStore/globalStore'
import { languageStore } from 'stores/languageStore'
import { AnyObject } from 'utils/types'
import App from './App'
import { routerStore } from './stores/routerStore'

configure({
  enforceActions: 'observed',
})
window.__forceSmoothScrollPolyfill__ = true
smoothscroll.polyfill()

declare global {
  interface Window {
    /* eslint-disable-next-line */
    __forceSmoothScrollPolyfill__: boolean
    /* eslint-enable */
    contentStore: ContentStore
    opera: string
    MemberStack: {
      reload(): void
      onReady: Promise<AnyObject>
    }
  }
}

const repoName = process.env.REACT_APP_PRISMIC_REPO_NAME ?? ''

const script = document.createElement('script')
script.src = `https://static.cdn.prismic.io/prismic.min.js?repo=${repoName}&new=true`
script.async = true
script.defer = true
script.type = 'text/javascript'
document.body.appendChild(script)

const browserHistory = createBrowserHistory()
export const history = syncHistoryWithStore(browserHistory, routerStore)

async function startApp() {
  const prismicAdapter = getAdapter()
  await loadConfig()
  prismicAdapter.initPrismicPath()
  prismicAdapter.getApi()
  languageStore.loadLanguages(appConfig.languages)

  const theme = createMuiTheme(fullTheme)

  ReactDOM.render(
    <Router history={history}>
      <ThemeProvider theme={theme}>
        <Route component={App} props />
      </ThemeProvider>
    </Router>,
    document.getElementById('root')
  )
}

startApp().catch((err) => console.error(err))
