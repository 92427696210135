/* eslint-disable @typescript-eslint/no-explicit-any */
import { bind } from 'bind-decorator'
import { appConfig } from 'config'
import { computed, runInAction } from 'mobx'
import Prismic from 'prismic-javascript'
import ApiSearchResponse from 'prismic-javascript/types/ApiSearchResponse'
import { QueryOptions } from 'prismic-javascript/types/ResolvedApi'
import {
  IChapter,
  IIntroPage,
  ISubchapter,
  ISubchapterPrismicData,
  IUIElements,
  LegalContent,
} from './contentStore/contentTypes'
import { IGlossary } from './contentStore/materials'
import { IRawSubcontentData } from './contentStore/subcontent_modules/subcontentInterfaces'
import { languageStore } from './languageStore'
import * as prismicHelper from './prismicHelpers'

type Unpacked<T> = T extends Array<infer U>
  ? U
  : T extends (...args: any[]) => infer U
  ? U
  : T extends Promise<infer U>
  ? U
  : T

type PrismicApiPromise = ReturnType<typeof Prismic.getApi>
type PrismicApi = Unpacked<PrismicApiPromise>
type PrismicDocument = Unpacked<ReturnType<PrismicApi['getSingle']>>

export class PrismicAdapter {
  _api: PrismicApiPromise = {} as any
  _prismicPath: string = ''

  @computed
  get defaultFetchOption() {
    return {
      pageSize: 100,
      lang: languageStore.language,
    }
  }

  @computed
  get defaultFetchOptionPageOne() {
    return {
      page: 1,
      ...this.defaultFetchOption,
    }
  }

  initPrismicPath() {
    this._prismicPath = appConfig.prismicPath
  }

  getApi() {
    this._api = Prismic.getApi(this._prismicPath)
  }

  @bind
  async getAllResults(q: string | string[], maybeOptions?: QueryOptions) {
    let results: PrismicDocument[] = []
    const api = await this._api
    let page = 1
    let more = false

    do {
      const r: ApiSearchResponse = await api.query(q, {
        ...maybeOptions,
        page: page,
      })
      page = r.page + 1
      more = page <= r.total_pages
      results = [...results, ...r.results]
    } while (more)
    return results
  }

  async getChapterLanguage(chapterUid: string) {
    const api = await this._api
    const results = (
      await api.query(
        Prismic.Predicates.at(
          `my.${appConfig.prismicModelsNamesMap.page}.uid`,
          chapterUid
        ),
        { lang: '*' }
      )
    ).results
    const languages: string[] = results
      .map((res) => res?.lang)
      .filter((lang) => lang !== undefined) as string[]
    if (languages.length) {
      return languages
    }
    return null
  }

  async fetchChapters(
    options?: QueryOptions,
    fields?: { [key: string]: string },
    ids?: string[]
  ) {
    const api = await this._api
    const fieldQueries = fields
      ? Object.keys(fields).map((f) => {
          return Prismic.Predicates.at(
            `my.${appConfig.prismicModelsNamesMap.page}.${f}`,
            fields[f]
          )
        })
      : []
    const query =
      ids === undefined
        ? Prismic.Predicates.at(
            'document.type',
            appConfig.prismicModelsNamesMap.page
          )
        : Prismic.Predicates.in('document.id', ids)
    if (options?.fetchAll) {
      delete options.fetchAll
      const results = await this.getAllResults([query, ...fieldQueries], {
        ...options,
        ...this.defaultFetchOptionPageOne,
      })
      return this.saveChapters(results, true)
    }
    const { results, total_pages: nOfPages } = await api.query(
      [query, ...fieldQueries],
      {
        ...this.defaultFetchOptionPageOne,
        ...options,
      }
    )
    return this.saveChapters(results, !!(options?.page === nOfPages))
  }

  async fetchChaptersByIds(ids: string[], options?: QueryOptions) {
    const results = await this.getAllResults(
      [Prismic.Predicates.in('document.id', ids)],
      {
        ...options,
        ...this.defaultFetchOption,
      }
    )
    return this.saveChapters(results, true)
  }

  saveChapters(data: prismicHelper.IPrismicChapter[], isLastPage: boolean) {
    const chapters: IChapter[] = []
    for (const chapter of data) {
      runInAction(() => {
        const newChapter = prismicHelper.parseChapter(chapter)
        chapters.push(newChapter)
      })
    }
    return {
      data: chapters.filter((chapter: IChapter) => chapter.idx >= 0),
      isLastPage,
    }
  }

  async fetchSubchapters(
    ids?: string[],
    options?: QueryOptions,
    fields?: { [key: string]: string }
  ) {
    const fieldQueries = fields
      ? Object.keys(fields).map((f) => {
          return Prismic.Predicates.at(
            `my.${appConfig.prismicModelsNamesMap.section}.${f}`,
            fields[f]
          )
        })
      : []
    const query =
      ids === undefined
        ? Prismic.Predicates.at(
            'document.type',
            appConfig.prismicModelsNamesMap.section
          )
        : Prismic.Predicates.in('document.id', ids)
    const results = await this.getAllResults([query, ...fieldQueries], {
      ...options,
      ...this.defaultFetchOption,
    })
    const subchapters: ISubchapter[] = []
    for (const subchapter of results) {
      const newSubchapter: ISubchapter = await this._buildSubchapter(subchapter)
      subchapters.push(newSubchapter)
    }
    return subchapters
  }

  protected async _buildSubchapter(subchapterData: ISubchapterPrismicData) {
    const subcontent = await prismicHelper.parseSubcontent(
      subchapterData.data.body
    )
    const newSubchapter: ISubchapter = prismicHelper.createSubchapter(
      subchapterData,
      subcontent
    )
    return newSubchapter
  }

  async fetchFullscreenSubcontent(query?: string[]) {
    let extraQuery: string[] = []
    if (query) {
      extraQuery = query
    }
    const results = await this.getAllResults(
      [
        Prismic.Predicates.any(
          'document.type',
          appConfig.prismicModelsNamesMap.subcontentNamesArray
        ),
        ...extraQuery,
      ],
      this.defaultFetchOption
    )
    const subcontentData: IRawSubcontentData[] = []
    for (const subcontent of results) {
      subcontentData.push(prismicHelper.parseFullScreenSubcontent(subcontent))
    }
    return subcontentData
  }

  async fetchVideoSubcontents() {
    const videoQuery = Prismic.Predicates.at(
      'document.type',
      appConfig.prismicModelsNamesMap.subcontentNamesArray[0]
    )
    return this.fetchFullscreenSubcontent([videoQuery])
  }

  async fetchImpressum() {
    const results = await this.getAllResults(
      Prismic.Predicates.at(
        'document.type',
        appConfig.prismicModelsNamesMap.legal
      ),
      this.defaultFetchOption
    )
    const impressum: LegalContent[] = []
    for (const document of results) {
      if (
        document.slugs.indexOf(appConfig.prismicModelsNamesMap.impressumSlug) >=
        0
      ) {
        const parsedImpressum = prismicHelper.parseImpressum(document)
        impressum.push(parsedImpressum)
      }
    }
    return impressum[0]
  }

  async fetchQuellen() {
    const results = await this.getAllResults(
      Prismic.Predicates.at(
        'document.type',
        appConfig.prismicModelsNamesMap.legal
      ),
      this.defaultFetchOption
    )
    const quellen: LegalContent[] = []
    for (const document of results) {
      if (
        document.slugs.indexOf(appConfig.prismicModelsNamesMap.quellenSlug) >= 0
      ) {
        const parsedQuellen = prismicHelper.parseQuellen(document)
        quellen.push(parsedQuellen)
      }
    }
    return quellen[0]
  }

  async fetchDataPrivacy() {
    const results = await this.getAllResults(
      Prismic.Predicates.at(
        'document.type',
        appConfig.prismicModelsNamesMap.legal
      ),
      this.defaultFetchOption
    )
    const dataPrivacy: LegalContent[] = []
    for (const document of results) {
      if (
        document.slugs.indexOf(
          appConfig.prismicModelsNamesMap.dataPrivacySlug
        ) >= 0
      ) {
        const parsedDataPrivacy = prismicHelper.parseDataPrivacy(document)
        dataPrivacy.push(parsedDataPrivacy)
      }
    }
    return dataPrivacy[0]
  }

  async fetchGlossary() {
    const results = await this.getAllResults(
      Prismic.Predicates.at('document.type', 'glossar'),
      this.defaultFetchOption
    )
    let glossary: IGlossary | null = null
    if (!results[0]) {
      return
    }
    glossary = prismicHelper.parseGlossary(results[0])
    return glossary
  }

  async fetchIntroPage() {
    const api = await this._api
    const introPage: IIntroPage[] = []
    for (const language of languageStore.languages) {
      const lang = language.code
      const results = (
        await api.query(Prismic.Predicates.at('document.type', 'intro_page'), {
          lang,
        })
      ).results
      if (!results[0]) {
        const initializedIntroPage = prismicHelper.initIntroPage(lang)
        introPage.push(initializedIntroPage)
        continue
      }
      const data = results[0].data
      introPage.push({
        lang,
        ...prismicHelper.parseIntro(data),
      })
    }
    return introPage
  }

  async fetchUIElements(
    additionalHandlers?: Array<{
      forType: string
      handlerFunc: (item: unknown, uiElements: IUIElements) => void
    }>
  ) {
    const results = await this.getAllResults(
      Prismic.Predicates.at('document.type', 'ui_elements'),
      this.defaultFetchOption
    )
    if (!results || !results[0]) {
      return null
    }
    const uiElements: IUIElements = prismicHelper.parseCommonUIElements(results)

    for (const item of results[0].data.body) {
      if (item.slice_type === 'unlock_content') {
        uiElements.unlockContent =
          prismicHelper.parseUnlockContent(item) ?? ({} as any)
      }
      if (item.slice_type === 'ie_banner') {
        uiElements.ieBanner = prismicHelper.parseIEBanner(item) ?? ({} as any)
      }
      if (item.slice_type === 'cookies_banner') {
        uiElements.cookiesBanner =
          prismicHelper.parseCookiesBanner(item) ?? ({} as any)
      }
      if (item.slice_type === 'meta_tabs') {
        uiElements.metaTabs = prismicHelper.parseMetaTabs(item) ?? []
      }
      if (item.slice_type === 'video_documentary_banner') {
        uiElements.videoBanner =
          prismicHelper.parseVideoBanner(item) ?? ({} as any)
      }
      if (item.slice_type === 'menu_items') {
        uiElements.menuItems = prismicHelper.parseMenuItems(item) ?? ({} as any)
      }
      if (item.slice_type === 'static_ui_texts') {
        uiElements.staticUITexts = prismicHelper.parseUIElementStaticUIText(
          item
        )
      }
      if (item.slice_type === 'loading_screen') {
        uiElements.loadingScreen = prismicHelper.parseUIElementLoadingScreen(
          item
        )
      }
      if (item.slice_type === 'podcast') {
        uiElements.podcast = prismicHelper.parseUIElementPodcast(item)
      }
      if (item.slice_type === 'marketing_popup') {
        uiElements.signupBanner = prismicHelper.parseUIElementMarketingPopup(
          item
        )
      }

      if (additionalHandlers) {
        additionalHandlers.forEach((additionalHandler) => {
          if (item.slice_type === additionalHandler.forType) {
            additionalHandler.handlerFunc(item, uiElements)
          }
        })
      }
    }
    return uiElements
  }

  async fetchChapterByUid(uid: string) {
    const api = await this._api
    let results: prismicHelper.IPrismicChapter[] = []
    try {
      results = (
        await api.query(
          Prismic.Predicates.at(
            `my.${appConfig.prismicModelsNamesMap.page}.uid`,
            uid
          ),
          {
            lang: languageStore.language,
          }
        )
      ).results
    } catch (error) {
      console.log(error)
    }
    if (!results || !results[0]) {
      return null
    }
    const newChapter = prismicHelper.parseChapter(results[0])
    return newChapter
  }

  async fetchById(id: string) {
    if (!id) {
      return null
    }
    const api = await this._api
    let results: Array<prismicHelper.IPrismicChapter | IRawSubcontentData> = []
    try {
      results = (
        await api.query(Prismic.Predicates.at('document.id', id), {
          lang: languageStore.language,
        })
      ).results
    } catch (error) {
      console.log(error)
    }
    if (!results || !results[0]) {
      return null
    }
    const result = prismicHelper.parseFetchedById(results[0])
    return result
  }

  async fetchByIds(ids: string[]) {
    const api = await this._api
    let results: Array<prismicHelper.IPrismicChapter | IRawSubcontentData> = []
    try {
      results = (
        await api.getByIDs(ids, {
          lang: languageStore.language,
        })
      ).results
      if (!results || !results[0]) {
        return null
      }
      const result = results.map((_, idx) => {
        return prismicHelper.parseFetchedById(results[idx])
      })
      return result
    } catch (error) {
      console.log(error)
    }
  }
}

export const prismicAdapter = new PrismicAdapter()
