import { appConfig } from 'config'
import { runInAction } from 'mobx'
import { update } from 'serializr'
import { getAdapter } from 'stores/adapterHelper'
import { Chapter } from 'stores/contentStore/contentTypes'
import { ContentStore } from 'stores/contentStore/globalStore'
import { languageStore } from 'stores/languageStore'
import { routerStore } from 'stores/routerStore'
import { NovartisPrismicAdapter } from './prismicAdapter'

export class NovartisContentStore extends ContentStore {
  prismicAdapter = getAdapter() as NovartisPrismicAdapter

  async checkIfChapterExists(chapterUid?: string) {
    await this.fetchData()
    if (chapterUid === undefined) {
      if (!this.chaptersFetchedAndSet) {
        await this.fetchChapters()
      }
      return true
    } else {
      if (!this.getChapterByUid(chapterUid)) {
        await this.fetchChapterByUidAndSave(chapterUid)
      }
    }
    let foundChapter: Chapter | undefined
    foundChapter = this.getChapterByUid(chapterUid)
    if (foundChapter) {
      return foundChapter
    }
    // Chapter not found, means it (1) exists in a different language or (2) language has been switched and page reloaded or (3) does not exist at all
    const languages:
      | string[]
      | null = await this.prismicAdapter.getChapterLanguage(chapterUid)
    if (!languages) {
      // Chapter not found in Primic at all, process error
      console.error('Chapter with this UID does not exist')
      routerStore.push(`/${appConfig.prismicModelsNamesMap.page}`)
      return
    }
    let lang = ''
    // If chapter exists only in one language OR currenly selected language is not one of the chapter languages
    if (languages.length === 1 || !languages.includes(languageStore.language)) {
      lang = languages[0]
      languageStore.selectLanguage(lang)
      const hub = await this.prismicAdapter.fetchHub()

      runInAction(() => {
        update(NovartisContentStore, this, { hub: hub })
      })
    }
    await this.fetchChapterByUidAndSave(chapterUid)
    foundChapter = this.getChapterByUid(chapterUid)
    if (foundChapter) {
      return foundChapter
    }
  }

  async fetchHub() {
    const hub = await this.prismicAdapter.fetchHub()

    runInAction(() => {
      update(NovartisContentStore, this, { hub: hub }, undefined)
    })
  }

  async fetchUIElems() {
    const uiElementsData = await this.prismicAdapter.fetchUIElements()
    await this.fetchAllPodcasts(uiElementsData?.podcastSeries)
    runInAction(() => {
      update(ContentStore, this, { uiElements: uiElementsData }, () =>
        this.setUiFetched(true)
      )
    })
  }

  async fetchData() {
    const glossary = await this.prismicAdapter.fetchGlossary()
    const hub = await this.prismicAdapter.fetchHub()

    runInAction(() => {
      update(NovartisContentStore, this, { glossary })
      update(NovartisContentStore, this, { hub })
    })

    await this.fetchUIElems()
  }

  clearHub() {
    runInAction(() => {
      update(
        NovartisContentStore,
        this,
        { hub: null, chapters: [], uiElements: null },
        () => {
          this.clearCategoryFilter()
          this.setUiFetched(false)
          runInAction(() => {
            this.chaptersFetchedAndSet = false
          })
        }
      )
    })
  }

  async fetchParentChapterBySubchapterId(subchapterId: string) {
    const chapter = (
      await this.prismicAdapter.fetchChapters(
        undefined,
        {
          'subchapters.subchapter': subchapterId,
        },
        undefined
      )
    )['data'][0]
    runInAction(() => {
      update(NovartisContentStore, this, { chapter })
    })
  }
}

export const contentStore = new NovartisContentStore()
