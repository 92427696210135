import Prismic from 'prismic-javascript'
import { IKnowledgeBase } from 'stores/contentStore/contentTypes'
import { languageStore } from 'stores/languageStore'
import { initKnowledgeBase, parseKnowledgeBase } from './prismicHelpers'
import { PrismicAdapter } from 'stores/prismicAdapter'

export class NuclearGamesAdapter extends PrismicAdapter {
  async fetchKnowledgeBase() {
    const api = await this._api
    const knowledgeBase: IKnowledgeBase[] = []
    for (const language of languageStore.languages) {
      const lang = language.code
      const results = (
        await api.query(
          Prismic.Predicates.at('document.type', 'knowledge_base'),
          {
            lang,
          }
        )
      ).results
      if (!results[0]) {
        const initializedKnowledgeBase = initKnowledgeBase(lang)
        knowledgeBase.push(initializedKnowledgeBase)
        continue
      }
      const data = results[0].data
      const parsedKnowledgeBase = parseKnowledgeBase(data, lang)
      knowledgeBase.push(parsedKnowledgeBase)
    }

    return knowledgeBase
  }
}

export const prismicAdapter = new NuclearGamesAdapter()
