import { bind } from 'bind-decorator'
import { appConfig } from 'config'
import { action, observable, runInAction, toJS } from 'mobx'
import { QueryOptions } from 'prismic-javascript/types/ResolvedApi'
import { list, mapAsArray, object, serializable, update } from 'serializr'
import { getAdapter } from 'stores/adapterHelper'
import FullscreenSubcontentVideo from 'stores/contentStore/subcontent_modules/video'
import { languageStore } from 'stores/languageStore'
import { routerStore } from '../routerStore'
import {
  Chapter,
  Hub,
  IChapter,
  IntroPage,
  ISubchapter,
  KnowledgeBase,
  LegalContent,
  Series,
  Subchapter,
  UIElements,
} from './contentTypes'
import { Glossary } from './materials'
import { mapAsArrayWithMerge, mapListFactoryAsync } from './mobxHelpers'
import { createSubcontent, FullscreenSubcontent } from './subcontent_modules'
import { IRawSubcontentData } from './subcontent_modules/subcontentInterfaces'

export class ContentStore {
  prismicAdapter = getAdapter()

  @serializable(list(object(Chapter)))
  @observable
  chapters: Chapter[] = []

  @observable filteredChaptersIds: string[] = []

  @serializable(mapAsArrayWithMerge(Subchapter, 'id'))
  @observable
  subchapters: Map<string, Subchapter> = observable.map(new Map(), {
    deep: false,
  })

  subcontentIsFetching: Map<string, boolean> = observable.map(new Map(), {
    deep: false,
  })

  @serializable(mapListFactoryAsync('id', createSubcontent))
  @observable
  fullscreenSubcontent: Map<
    string,
    FullscreenSubcontent
  > = observable.map(new Map(), { deep: false })

  @serializable(object(Glossary))
  @observable
  glossary: Glossary | null = null

  @serializable(object(Hub))
  @observable
  hub: Hub | null = null

  @serializable(mapAsArray(object(IntroPage), 'lang'))
  @observable
  introPage: { [language: string]: IntroPage } = {}

  @serializable(mapAsArray(object(KnowledgeBase), 'lang'))
  @observable
  knowledgeBase: { [language: string]: KnowledgeBase } = {}

  @serializable(object(LegalContent))
  @observable
  impressum: LegalContent | null = null

  @serializable(object(LegalContent))
  @observable
  quellen: LegalContent | null = null

  @serializable(object(LegalContent))
  @observable
  dataPrivacy: LegalContent | null = null

  @serializable(object(UIElements))
  @observable
  uiElements: UIElements | null = null

  @observable categoryFilter: string | undefined = this.uiElements
    ?.staticUITexts?.allStories

  @observable
  subcontentResourcesLoaded: Map<string, boolean> = observable.map(new Map(), {
    deep: true,
  })

  @observable
  chaptersFetchedAndSet: boolean = false

  @observable
  uiFetched: boolean = false

  @bind
  @action
  setUiFetched(value: boolean) {
    this.uiFetched = value
  }

  async fetchQuellen() {
    const quellenData = await this.prismicAdapter.fetchQuellen()

    runInAction(() => {
      update(ContentStore, this, { quellen: quellenData })
    })
  }

  async fetchImpressum() {
    const impressumData = await this.prismicAdapter.fetchImpressum()
    runInAction(() => {
      update(ContentStore, this, { impressum: impressumData })
    })
  }

  async fetchDataPrivacy() {
    const privacyData = await this.prismicAdapter.fetchDataPrivacy()
    runInAction(() => {
      update(ContentStore, this, { dataPrivacy: privacyData })
    })
  }

  getSubcontentIfExists(id: string) {
    if (this.fullscreenSubcontent.get(id)) {
      return this.fullscreenSubcontent.get(id)
    }
    this.fetchSubcontentById(id)
      .then()
      .catch((err) => console.error(err))
    return null
  }

  async fetchSubcontentById(id: string) {
    if (this.subcontentIsFetching.has(id)) {
      return
    }
    this.subcontentIsFetching.set(id, true)
    const data = await this.prismicAdapter.fetchById(id)
    if (!data) {
      return null
    }
    const bg = await createSubcontent(data as IRawSubcontentData)
    if (!bg) {
      return null
    }
    runInAction(() => {
      this.fullscreenSubcontent.set(id, bg)
      this.subcontentIsFetching.delete(id)
    })
  }

  async fetchIntro() {
    const introPageData = await this.prismicAdapter.fetchIntroPage()

    runInAction(() => {
      update(ContentStore, this, { introPage: introPageData }, undefined)
    })
  }

  async checkIfChapterExists(chapterUid?: string) {
    await this.fetchData()
    if (chapterUid === undefined) {
      if (!this.chaptersFetchedAndSet) {
        await this.fetchChapters()
      }
      return true
    } else {
      if (!this.getChapterByUid(chapterUid)) {
        await this.fetchChapterByUidAndSave(chapterUid)
      }
    }
    let foundChapter: Chapter | undefined
    foundChapter = this.getChapterByUid(chapterUid)
    if (foundChapter) {
      return foundChapter
    }
    // Chapter not found, means it (1) exists in a different language or (2) language has been switched and page reloaded or (3) does not exist at all
    const languages:
      | string[]
      | null = await this.prismicAdapter.getChapterLanguage(chapterUid)
    if (!languages) {
      // Chapter not found in Primic at all, process error
      console.error('Chapter with this UID does not exist')
      routerStore.push(`/${appConfig.prismicModelsNamesMap.page}`)
      return
    }
    let lang = ''
    // If chapter exists only in one language OR currenly selected language is not one of the chapter languages
    if (languages.length === 1 || !languages.includes(languageStore.language)) {
      lang = languages[0]
      languageStore.selectLanguage(lang)
    }
    await this.fetchChapterByUidAndSave(chapterUid)
    foundChapter = this.getChapterByUid(chapterUid)
    if (foundChapter) {
      return foundChapter
    }
  }

  async fetchAllPodcasts(series: Series[] | undefined) {
    if (!series || series.length === 0) {
      return
    }
    const subcontentPromises = []
    for (const serie of series) {
      for (const podcastId of serie.podcastIds) {
        subcontentPromises.push(this.fetchSubcontentById(podcastId))
      }
    }
    await Promise.all(subcontentPromises)
  }

  async fetchChapters(
    options?: QueryOptions,
    fields?: { [key: string]: string },
    callback?: (isLastPage: boolean) => void,
    ids?: string[],
    saveToStore = true,
    mergeChapters = false
  ) {
    const {
      data: chapterData,
      isLastPage,
    } = await this.prismicAdapter.fetchChapters(options, fields, toJS(ids))
    // For regular fetch(not by IDs) save to store
    if (saveToStore) {
      this.sortAndSaveChapters(chapterData, isLastPage, mergeChapters, callback)
    } else {
      return chapterData.sort(appConfig.chaptersSortingFunction)
    }
  }

  sortAndSaveChapters(
    chapters: IChapter[],
    isLastPage: boolean,
    mergeChapters: boolean,
    callback?: (isLastPage: boolean) => void
  ) {
    const chaptersData = mergeChapters
      ? [...toJS(this.chapters), ...chapters]
      : chapters
    const sortedChapterData = chaptersData.sort(
      appConfig.chaptersSortingFunction
    )
    runInAction(() => {
      update(ContentStore, this, { chapters: sortedChapterData }, () => {
        runInAction(() => {
          this.chaptersFetchedAndSet = true
          callback?.(isLastPage)
        })
      })
    })
  }

  async fetchSubchapters(ids?: string[], callback?: () => void) {
    const subchaptersData = await this.prismicAdapter.fetchSubchapters(
      toJS(ids)
    )

    runInAction(() => {
      update(ContentStore, this, { subchapters: subchaptersData }, () => {
        callback?.()
      })
    })
  }

  async fetchData() {
    const glossaryData = await this.prismicAdapter.fetchGlossary()

    runInAction(() => {
      update(ContentStore, this, { glossary: glossaryData })
      update(ContentStore, this, { hub: {} })
    })

    await this.fetchUIElems()
  }

  @bind
  @action
  setCategoryFilter(filter: string | undefined) {
    if (filter && filter !== this.categoryFilter) {
      this.categoryFilter = filter
    }
  }

  @bind
  @action
  clearCategoryFilter() {
    this.categoryFilter = undefined
  }

  getChapterByUid(uid: string) {
    return this.chapters.find((chapter) => chapter.uid === uid)
  }

  async fetchChapterByUidAndSave(uid: string) {
    const chapter = await this.prismicAdapter.fetchChapterByUid(uid)
    if (chapter) {
      this.sortAndSaveChapters([chapter], true, true)
    }
  }

  getParentSubchapterBySubcontentId(
    subcontentId: string
  ): Subchapter | undefined {
    let result: Subchapter | undefined
    for (const [, subchapter] of this.subchapters) {
      if (subchapter.subcontentId === subcontentId) {
        result = subchapter
      }
    }
    return result
  }

  @bind
  async fetchParentSubchapterBySubcontentId(
    subcontentId: string
  ): Promise<ISubchapter | undefined> {
    const subchapter = (
      await this.prismicAdapter.fetchSubchapters(
        undefined,
        undefined,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        { bg_main: subcontentId }
      )
    )[0]
    return subchapter
  }

  getParentChapterBySubchapterId(subchapterId: string): Chapter | undefined {
    return this.chapters.find(
      (chapter) => chapter.subchaptersIds.indexOf(subchapterId) >= 0
    )
  }

  getParentChapterBySubcontentId(subcontentId: string): Chapter | undefined {
    const parentSubchapter = this.getParentSubchapterBySubcontentId(
      subcontentId
    )
    if (parentSubchapter) {
      return this.getParentChapterBySubchapterId(parentSubchapter.id)
    }
  }

  getChapterIndex(chapter: Chapter) {
    const indexToReturn = this.chapters.indexOf(chapter)
    return indexToReturn >= 0 ? indexToReturn : 0
  }

  getChapterIndexById(id: string) {
    for (const [index, chapter] of this.chapters.entries()) {
      if (chapter?.id === id) {
        return index
      }
    }
    return -1
  }

  getChapterShiftedBy(shift: number, chapter?: Chapter) {
    if (!chapter) {
      return
    }
    const currentChapterIndex = this.chapters.indexOf(chapter)
    if (currentChapterIndex <= 0) {
      return
    }
    const newIndex = currentChapterIndex + shift
    if (newIndex >= 0 && newIndex < this.chapters.length) {
      return this.chapters[newIndex]
    }
  }

  getSubcontentShiftedBy(id: string, shift: number) {
    const parentChapter = this.getParentChapterBySubcontentId(id)
    const parentSubchapter = this.getParentSubchapterBySubcontentId(id)
    let currentSectionIndex = -1
    if (parentChapter && parentSubchapter) {
      currentSectionIndex = parentChapter?.subchaptersIds.indexOf(
        parentSubchapter?.id
      )
      if (currentSectionIndex >= 0) {
        const newIndex = currentSectionIndex + shift
        if (newIndex >= 0 && newIndex < parentChapter?.subchaptersIds.length) {
          const shiftedSection = this.subchapters.get(
            parentChapter?.subchaptersIds[newIndex]
          )
          return (
            shiftedSection &&
            this.fullscreenSubcontent.get(shiftedSection.subcontentId)
          )
        }
      }
    }
  }

  @bind
  setResourcesLoadStatus(id: string, status: boolean) {
    this.subcontentResourcesLoaded.set(id, status)
  }

  @bind
  @action
  async saveFullscreenSubcontent(data: IRawSubcontentData[]) {
    const result: FullscreenSubcontent[] = []
    for (const dataItem of data) {
      const subcontent = await createSubcontent(dataItem)
      if (!subcontent) {
        continue
      }
      result.push(subcontent)
      runInAction(() => {
        this.fullscreenSubcontent.set(dataItem.id, subcontent)
      })
    }
    return result
  }

  @bind
  @action
  async fetchAndSaveVideoSubcontent() {
    const subcontentData = await this.prismicAdapter.fetchVideoSubcontents()
    const result: FullscreenSubcontentVideo[] = (await this.saveFullscreenSubcontent(
      subcontentData
    )) as FullscreenSubcontentVideo[]
    return result
  }

  @bind
  @action
  async fetchUIElems() {
    const uiElementsData = await this.prismicAdapter.fetchUIElements()
    runInAction(() => {
      update(ContentStore, this, { uiElements: uiElementsData }, () =>
        this.setUiFetched(true)
      )
    })
  }
}

export const globalStore = new ContentStore()
