import Prismic from 'prismic-javascript'
import { deserialize } from 'serializr'
import {
  Chapter,
  IHub,
  IHubPrismicData,
  ISubchapterPrismicData,
  PartialChapter,
  Subchapter,
} from 'stores/contentStore/contentTypes'
import { languageStore } from 'stores/languageStore'
import { PrismicAdapter } from 'stores/prismicAdapter'
import { IPrismicChapter, parseChapter } from 'stores/prismicHelpers'
import {
  createPartialChapter,
  parseHub,
  _parseDossier,
  _parsePodcastSeries,
} from './prismicHelpers'

export class NovartisPrismicAdapter extends PrismicAdapter {
  async fetchSubchapterById(id: string) {
    const api = await this._api
    let results: ISubchapterPrismicData[] = []
    try {
      results = (
        await api.query(Prismic.Predicates.at('document.id', id), {
          lang: languageStore.language,
        })
      ).results
    } catch (error) {
      console.log(error)
    }
    if (!results || !results[0]) {
      return null
    }
    const newSubchapter = await this._buildSubchapter(results[0])
    return deserialize(Subchapter, newSubchapter)
  }

  async fetchUIElements() {
    const result = super.fetchUIElements([
      { forType: 'podcast_series', handlerFunc: _parsePodcastSeries },
      { forType: 'dossier', handlerFunc: _parseDossier },
    ])

    return result
  }

  async fetchChapterById(id?: string) {
    if (!id) {
      return
    }
    const api = await this._api
    let results: IPrismicChapter[] = []
    try {
      results = (
        await api.query(Prismic.Predicates.at('document.id', id), {
          lang: languageStore.language,
        })
      ).results
    } catch (error) {
      console.log(error)
    }
    if (!results || !results[0]) {
      return null
    }
    const newChapter = deserialize(Chapter, parseChapter(results[0]))
    return newChapter
  }

  async fetchHub() {
    const api = await this._api
    let Hub: IHub | null = null
    let results: IHubPrismicData[] = []
    try {
      results = (
        await api.query(Prismic.Predicates.at('document.type', 'hub'), {
          lang: languageStore.language,
        })
      ).results
    } catch (error) {
      console.log(error)
    }
    if (!results || !results[0]) {
      Hub = null
    } else {
      const data = results[0].data
      const featuredData1 = await this.fetchById(data.featured_article_1.id)
      const featuredData2 = await this.fetchById(data.featured_article_2.id)
      const featuredArticlesTopBannerIds = data.featured_top_banner.map(
        (val) => val.featured_article_top_banner.id
      )
      const featured1: PartialChapter | null = featuredData1
        ? createPartialChapter(featuredData1.data as IPrismicChapter)
        : null
      const featured2: PartialChapter | null = featuredData2
        ? createPartialChapter(featuredData2.data as IPrismicChapter)
        : null

      const hub = parseHub(
        data,
        featuredArticlesTopBannerIds,
        featured1,
        featured2
      )
      Hub = hub
    }
    return Hub
  }
}

export const prismicAdapter = new NovartisPrismicAdapter()
