import * as PrismicDOM from 'prismic-dom'

export const initKnowledgeBase = (lang: string) => {
  return {
    lang,
    buttonTextToMain: '',
    title: '',
    subtitle: '',
    description: '',
    logoTopLeft: '',
    logoDescriptionTopLeft: '',
    backgroundImage: '',
  }
}

// TODO-Interface: Create interface
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const parseKnowledgeBase = (data: any, lang: string) => {
  return {
    lang,
    buttonTextToMain: data.button_text_to_main ?? '',
    title: data.title ?? '',
    subtitle: data.subtitle ?? '',
    description:
      (data.description && PrismicDOM.RichText.asHtml(data.description)) ?? '',
    logoTopLeft: data.logo_top_left.url ?? '',
    logoDescriptionTopLeft: data.logo_description_top_left ?? '',
    backgroundImage: data.background_image.url ?? '',
  }
}
